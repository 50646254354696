import { Dropdown } from '@/components'
import PropTypes from 'prop-types'

const DropDownContainer = ({ label, options, placeholder, id, formik, fieldInfo, disabled, hasSearch, dataTestId }) => {
  const updateValueArray = value => {
    const arr = formik.values[fieldInfo.key]
    arr[fieldInfo.index][id] = value
    return arr
  }

  const value = fieldInfo ? formik.values[fieldInfo.key][fieldInfo.index][id] : formik.values[id]

  const onChange = value =>
    fieldInfo ? formik.setFieldValue(fieldInfo.key, updateValueArray(value)) : formik.setFieldValue(id, value)
  const fieldError =
    fieldInfo && formik.errors[fieldInfo.key] && formik.errors[fieldInfo.key][fieldInfo.index]
      ? formik.errors[fieldInfo.key][fieldInfo.index][id]
      : formik.errors[id]
  const fieldTouched =
    fieldInfo && formik.touched[fieldInfo.key] && formik.touched[fieldInfo.key][fieldInfo.index]
      ? formik.touched[fieldInfo.key][fieldInfo.index][id]
      : formik.touched[id]

  // when "other" option is selected in the dropdown, show text field
  const onBlur = () => {
    if (fieldInfo) {
      formik.setFieldTouched(`${fieldInfo.key}[${fieldInfo.index}].${id}`, true)
    } else {
      formik.setFieldTouched(id, true)
    }
  }

  return (
    <Dropdown
      label={label}
      options={options}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className={fieldTouched && fieldError ? 'border-[--error-300]' : ''}
      helpText={
        fieldTouched && fieldError ? <p className="text-sm font-normal text-[--error-500]">{fieldError}</p> : null
      }
      disabled={disabled}
      hasSearch={hasSearch}
      id={id}
      dataTestId={dataTestId}
    />
  )
}

DropDownContainer.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
  placeholder: PropTypes.string,
  id: PropTypes.string,
  formik: PropTypes.object,
  fieldInfo: PropTypes.shape({ index: PropTypes.number, key: PropTypes.string }),
  disabled: PropTypes.bool,
  hasSearch: PropTypes.bool,
  dataTestId: PropTypes.string,
}

export { DropDownContainer }
