import { getCurrentUserSessionID } from './cognito-identity-service-provider'

export function saveLinkToken(linkToken) {
  const userID = getCurrentUserSessionID()
  localStorage.setItem(`PlaidProvider.${userID}.linkToken`, JSON.stringify(linkToken))
}

export function getLinkToken() {
  const userID = getCurrentUserSessionID()
  return JSON.parse(localStorage.getItem(`PlaidProvider.${userID}.linkToken`))
}
