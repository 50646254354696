import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '@/contexts/auth.context'
import { useEffect } from 'react'
import {
  ROUTE_APPLICATION_RESULT,
  ROUTE_EMAIL_VERIFICATION,
  ROUTE_ENFORCE_MFA,
  ROUTE_HOME,
  ROUTE_SIGN_IN,
  ROUTE_SIGN_UP,
} from './paths'
import {
  EMAIL_VERIFICATION_PROMPT,
  EMAIL_VERIFIED,
  EMAIL_VERIFICATION_EXPIRED,
  CARD_APPLICATION_PENDING,
  CARD_APPLICATION_APPROVED,
  CARD_APPLICATION_STATUSES,
} from '@/data/constants'
import {
  setVerificationDetails,
  persistIntendedRouteWithSearchParams,
  removeIntendedRouteWithSearchParams,
  getIntendedRouteWithSearchParams,
} from '@/storage'
import { confirmSignUp } from 'aws-amplify/auth'
import { useState } from 'react'
import LoadingScreen from '../components/loading-screen'
import PropTypes from 'prop-types'

// eslint-disable-next-line react-refresh/only-export-components
export const usePersistRoute = () => {
  const location = useLocation()

  useEffect(() => {
    // Persist the current route and search params on any route change
    localStorage.setItem('lastRoute', location.pathname)
    localStorage.setItem('lastRouteSearchParams', location.search)
  }, [location])
}

// eslint-disable-next-line react/prop-types
export const RedirectAuthenticated = ({ children }) => {
  const { authenticated, isMFAEnabled, updateMFAStatus, user } = useAuth()
  const cardApplicationState = user?.customer?.cardApplication?.[0]?.status ?? CARD_APPLICATION_PENDING
  const navigate = useNavigate()

  useEffect(() => {
    if (authenticated) {
      const [intendedRoute, intendedRouteSearchParams] = getIntendedRouteWithSearchParams()
      updateMFAStatus() // to re-update MFA status before checking
      if (!user.hasCustomer()) {
        // If the user is authenticated but does not have a customer profile, go to sign-up page
        navigate(ROUTE_SIGN_UP, { replace: true })
      } else if (!isMFAEnabled && cardApplicationState === CARD_APPLICATION_STATUSES[CARD_APPLICATION_APPROVED]) {
        // If the user has a customer profile, card application is approved & MFA is not enabled, redirect to the enforce MFA page
        navigate(ROUTE_ENFORCE_MFA, { replace: true })
      } else if (user.isBannerDismissed()) {
        // If the user has a customer profile, redirect to the last route or home page
        navigate(intendedRoute ? { pathname: intendedRoute, search: intendedRouteSearchParams } : `/${ROUTE_HOME}`, {
          replace: true,
        })
      } else {
        // If the user has a customer profile, redirect to the last route or home page
        navigate(ROUTE_APPLICATION_RESULT, { replace: true })
      }
    } else if (location.pathname !== ROUTE_SIGN_IN) {
      navigate(ROUTE_SIGN_IN, { replace: true })
    } else {
      // If the user is not authenticated, redirect to sign-in page
      navigate(ROUTE_SIGN_IN, { replace: true })
    }
  }, [authenticated, cardApplicationState, isMFAEnabled, navigate, updateMFAStatus, user])

  return authenticated ? null : children
}

// eslint-disable-next-line react/prop-types
export const ProtectedRoute = ({ children }) => {
  const { authenticated, user, isMFAEnabled, loading } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  usePersistRoute()

  useEffect(() => {
    const lastRoute = localStorage.getItem('lastRoute')
    const lastRouteSearchParams = localStorage.getItem('lastRouteSearchParams')

    const isInvalidRoute = lastRoute === '/' || lastRoute === ROUTE_SIGN_IN

    if (!user.hasCustomer() && localStorage.getItem('VerificationDetails')) {
      navigate(ROUTE_SIGN_UP)
    } else if (authenticated) {
      if (!user?.isBannerDismissed?.()) {
        navigate(ROUTE_APPLICATION_RESULT, { replace: true })
      } else if (!isInvalidRoute && lastRoute === location.pathname) {
        navigate({ pathname: lastRoute, search: lastRouteSearchParams }, { replace: true })
      } else if (user.isBannerDismissed()) {
        navigate('/' + ROUTE_HOME, { replace: true })
      } else {
        navigate(ROUTE_APPLICATION_RESULT, { replace: true })
      }
    } else {
      persistIntendedRouteWithSearchParams({ pathname: location.pathname, search: location.search })
      navigate(ROUTE_SIGN_IN, { replace: true })
    }
  }, [authenticated, user, navigate, location.pathname, location.search, isMFAEnabled, loading])

  if (!isMFAEnabled && user?.isBannerDismissed?.()) {
    // Persist intended route before redirecting to enforce MFA
    persistIntendedRouteWithSearchParams(location)
    navigate(ROUTE_ENFORCE_MFA, { replace: true })
  }

  if (authenticated) {
    if (!user.isBannerDismissed()) {
      // If authenticated and application results banner dismissed
      return <Navigate to={ROUTE_APPLICATION_RESULT} replace />
    } else if (user.hasCustomer()) {
      // If authenticated and application results banner dismissed and user has customer property, User can visit any protected page
      return children
    }
  } else {
    return <Navigate to={ROUTE_SIGN_UP} />
  }
}

// eslint-disable-next-line react/prop-types
export const ProtectedSignUp = ({ children }) => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const lastRoute = localStorage.getItem('lastRoute')

  const searchParams = new URLSearchParams(location.search)
  const code = searchParams.get('code')
  const email = searchParams.get('email')

  const [loading, setLoading] = useState(true) // Added loading state

  useEffect(() => {
    const lastRouteSearchParams = localStorage.getItem('lastRouteSearchParams')
    const handleSignUpConfirmation = async () => {
      if (code && email) {
        try {
          const { isSignUpComplete } = await confirmSignUp({
            username: email,
            confirmationCode: code,
          })

          if (isSignUpComplete) {
            setVerificationDetails({ status: EMAIL_VERIFIED })
            navigate(ROUTE_EMAIL_VERIFICATION, { state: EMAIL_VERIFIED, replace: true })
          } else {
            navigate(ROUTE_EMAIL_VERIFICATION, { state: EMAIL_VERIFICATION_PROMPT, replace: true })
          }
        } catch (error) {
          if (error.message === 'Invalid code provided, please request a code again.') {
            setVerificationDetails({ status: EMAIL_VERIFICATION_EXPIRED })
            navigate(ROUTE_EMAIL_VERIFICATION, { state: EMAIL_VERIFICATION_EXPIRED, replace: true })
          } else {
            console.error(error)
          }
        } finally {
          setLoading(false) // Set loading to false after the process is done
        }
      } else {
        if (!user.hasCustomer()) {
          navigate(ROUTE_SIGN_UP, { replace: true })
          // If first banner dismissed only user can visit home or routes within the app
        } else if (user?.isBannerDismissed?.()) {
          console.log('User already has a customer profile', user.hasCustomer() + '....')
          navigate(lastRoute ? { pathname: lastRoute, search: lastRouteSearchParams } : `/${ROUTE_HOME}`, {
            replace: true,
          })
        } else {
          // If not we need to show application results page
          navigate(ROUTE_APPLICATION_RESULT, { replace: true })
        }
        setLoading(false) // Set loading to false when not confirming sign-up
      }
    }

    handleSignUpConfirmation()
  }, [lastRoute, navigate, user, code, email])

  // Show a loading spinner or null while loading
  if (loading) {
    return <LoadingScreen />
  }

  return user.hasCustomer() ? null : children
}

export const ProtectedApplicationResult = ({ children }) => {
  const { authenticated, user, isCardApplicationSubmitted } = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    if (!authenticated) {
      navigate(ROUTE_SIGN_IN, { replace: true })
      // If user already dismissed banner, it means he is already visited home and it's better to not to show application results page
    } else if (user?.isBannerDismissed?.()) {
      navigate('/' + ROUTE_HOME, { replace: true })
    } else if (!user?.hasCustomer?.() && !isCardApplicationSubmitted) {
      // If user has no customer profile, it means he is not submitted card application yet and it's better to not to show application results page
      navigate(ROUTE_SIGN_UP, { replace: true })
    }
  }, [authenticated, navigate, user, isCardApplicationSubmitted])

  return !authenticated ? ROUTE_SIGN_IN : children
}

export const ProtectedEnforceMFA = ({ children }) => {
  const { authenticated, isMFAEnabled, loading, isPhoneNumberVerified, updatePhoneNumberStatus, updateMFAStatus } =
    useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    updatePhoneNumberStatus()
    if (!isMFAEnabled || !isPhoneNumberVerified) {
      navigate(ROUTE_ENFORCE_MFA, { replace: true })
    } else {
      const [intendedRoute, intendedRouteSearchParams] = getIntendedRouteWithSearchParams()
      // if intendedRoute exist go to that path with search params, otherwise go to /home
      navigate(intendedRoute ? { pathname: intendedRoute, search: intendedRouteSearchParams } : `/${ROUTE_HOME}`, {
        replace: true,
      })
      removeIntendedRouteWithSearchParams()
    }
  }, [authenticated, isMFAEnabled, isPhoneNumberVerified, navigate, updateMFAStatus, updatePhoneNumberStatus])

  if (loading) {
    return <LoadingScreen />
  }
  return !isMFAEnabled || !isPhoneNumberVerified ? children : null
}

ProtectedEnforceMFA.propTypes = {
  children: PropTypes.node.isRequired,
}
