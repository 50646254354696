import { END_POINTS } from './end-points'
import fetchWithAuthorization from './fetch-with-authorization'

export const sendOtp = async () => {
  return await fetchWithAuthorization({
    path: END_POINTS.SEND_OTP,
    method: 'POST',
  })
}

export const dismissFirstLoginModal = async () => {
  return await fetchWithAuthorization({
    path: END_POINTS.ON_BOARDING.DISMISS_FIRST_LOGIN_MODAL,
    method: 'PUT',
  })
}

export const updateCognitoPhone = async () => {
  return await fetchWithAuthorization({
    path: END_POINTS.UPDATE_COGNITO_PHONE,
    method: 'PUT',
  })
}

export const getConsentedDocs = async () => {
  return await fetchWithAuthorization({
    path: END_POINTS.ACCOUNT.GET_CONSENTED_DOC_VERSIONS,
    method: 'GET',
  })
}
