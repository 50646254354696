import { MONTHS } from '@/data/constants'
import { toZonedTime } from 'date-fns-tz'

const MONTH_MAP = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
}

//Format notification date and time
const formatNotificationTime = time => {
  const now = new Date()
  const difference = now - time
  if (difference <= 60000) {
    return 'now'
  } else if (difference > 1000 && difference <= 3600000) {
    let mins = difference / 60000
    return `${Math.round(mins)} mins ago`
  } else if (difference > 3600000 && difference <= 86400000) {
    let hrs = difference / 3600000
    return `${Math.round(hrs)} hrs ago`
  } else {
    return `${MONTHS[time?.getMonth()]} ${time?.getDate()}`
  }
}

//Converting UTC date times into ET date times
const convertUTCToET = utcTime => {
  if (utcTime) {
    const etDate = toZonedTime(utcTime, 'America/New_York')
    return etDate
  }
}

// Convert ET 'YYYY-MM-DD' to custom format
const formatETString = (etString, format) => {
  if (etString) {
    switch (format) {
      case 'MMM dd, yyyy': {
        //Mar 11, 2025
        const [year, month, day] = etString.split('-')
        return `${MONTH_MAP[month]} ${day}, ${year}`
      }
      case 'MM/dd/yy': {
        //03/11/25
        const [year, month, day] = etString.split('-')
        return `${month}/${day}/${year.slice(-2)}`
      }
      default:
        return etString
    }
  }
}

//Format dueDate to MM/DD/YY from accountSummary
const formatDueDate = data => {
  if (!data?.dueDate) return 'N/A'

  const date = new Date(data.dueDate)

  // Ensure the date is valid
  if (isNaN(date)) return 'Invalid Date'

  return new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  }).format(date)
}

//Get the current year
const getCurrentYear = () => {
  return new Date().getFullYear()
}

export { formatNotificationTime, convertUTCToET, formatDueDate, getCurrentYear, formatETString }
