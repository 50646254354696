import { io } from 'socket.io-client'

export const connectWebSocket = userId => {
  if (!userId) return null

  const socket = io(import.meta.env.VITE_SERVER_DOMAIN, {
    transports: ['websocket'],
    query: { userId },
    reconnectionAttempts: 5,
    reconnectionDelay: 3000,
  })

  return socket
}

export const disconnectWebSocket = socket => {
  if (socket) {
    socket.close()
  }
}
