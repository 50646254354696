import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Icons } from '@/theme/Images'
import { Button } from '@/components/index'
import { ExtendedTooltip } from '@/components'
import { Skeleton } from '@/components/ui/skeleton'
import { ROUTE_PAYMENTS, ROUTE_PAYMENTS_MAKE_PAYMENT } from '../../routes/paths'

const AccountSummarySkeleton = () => {
  const [showBalances, setShowBalances] = useState(false)

  return (
    <div data-testid="right-column">
      <div className="top-content w-full border-b-dGray-200 border-b-[1px] px-5 py-8">
        <div className="w-full flex flex-col md:flex-row md:flex-wrap justify-between items-start">
          <div className="w-full md:w-auto flex flex-row md:flex-col justify-between md:justify-start items-center">
            <div className="flex flex-col justify-start items-start">
              <div className="w-full flex flex-row justify-start items-center">
                <p className="font-semibold text-lg leading-5 text-dGray-900">Current balance</p>
                <ExtendedTooltip
                  trigger={<img src={Icons.helpIcon} className="cursor-pointer ml-2" width={13.5} />}
                  title={'Current balance'}
                  content="This may not include all transactions such as your most recent transactions, pending authorizations or interest that's accumulated since your last statement date."
                />
              </div>
              <Skeleton className="w-40 h-10 mt-3" data-testid="balance-field-sk" />
            </div>
            <div
              className="flex md:hidden flex-row justify-end items-center cursor-pointer"
              onClick={() => setShowBalances(prev => !prev)}
            >
              <p className="text-md font-semibold">{`Show ${showBalances ? 'less' : 'more'}`}</p>
              <img
                src={showBalances ? Icons.roundedGrayMinusIcon : Icons.roundedGrayPlusIcon}
                alt="icon"
                className="ml-2"
              />
            </div>
          </div>
          <div className={`${showBalances ? 'flex mt-4' : 'hidden'}  md:flex flex-col justify-start`}>
            <div className="flex flex-row justify-start items-center">
              <p className="font-normal text-[12px] text-dGray-600">Available credit</p>
              <ExtendedTooltip
                trigger={<img src={Icons.helpIcon} className="cursor-pointer ml-2" width={13.5} />}
                title={'Available credit'}
                content="The amount you can currently spend on your credit card. It's calculated as your total credit limit minus your current balance and any pending charges not yet posted to your account"
              />
            </div>
            <Skeleton className="w-28 h-7 mt-3" data-testid="balance-field-sk" />
          </div>
          <div className={`${showBalances ? 'flex mt-4' : 'hidden'}  md:flex flex-col justify-start`}>
            <p className="font-normal text-[12px] text-dGray-600">Credit line</p>
            <Skeleton className="w-28 h-7 mt-3" data-testid="balance-field-sk" />
          </div>
          <div className={`${showBalances ? 'flex mt-4' : 'hidden'}  md:flex flex-col justify-start`}>
            <p className="font-normal text-[12px] text-dGray-600">Last statement</p>
            <Skeleton className="w-28 h-7 mt-3" data-testid="balance-field-sk" />
          </div>
          <div className="w-full md:w-auto flex flex-col justify-start">
            <Link to={`/${ROUTE_PAYMENTS}${ROUTE_PAYMENTS_MAKE_PAYMENT}`} className="w-full mt-8 md:mt-0">
              <Button className="w-full bg-brand-600 hover:bg-brand-700 text-sm text-white">Make Payment</Button>
            </Link>
            <Skeleton className="w-36 h-4 mt-3 md:ml-5" />
            <div className="w-full flex flex-row justify-start md:justify-end items-center mt-2">
              <Skeleton className="w-44 h-4 mt-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountSummarySkeleton
