import { useState } from 'react'
import PropTypes from 'prop-types'

import { Button } from '@/components'
import { ROUTE_REWARDS, ROUTE_REWARDS_MY_REWARDS } from '@/routes/paths'
import useGetRewardBalance from '../hooks/use-get-reward-balance'
import { useNavigate } from 'react-router-dom'
import RewardsSkeleton from './rewards.skeleton'
const Rewards = () => {
  const navigate = useNavigate()
  const [showRewards, setShowRewards] = useState(false)

  const { isLoading, data: rewardsData } = useGetRewardBalance('quarter')

  if (isLoading) {
    return <RewardsSkeleton />
  }
  return (
    <div className="flex flex-col items-start md:p-5 px-5 md:mt-2">
      <div className="flex flex-row w-full justify-between items-center">
        <div className="flex flex-col items-start">
          <p className="text-lg font-semibold text-dGray-900">Available points</p>
          <p className="text-4xl font-semibold text-dGray-900 mb-4">
            {Number(rewardsData?.pointsBalance || 0).toLocaleString()}
          </p>
        </div>
        <div
          className="flex md:hidden flex-row justify-end items-center cursor-pointer"
          onClick={() => setShowRewards(prev => !prev)}
        >
          <p className="text-md font-semibold">{`Show ${showRewards ? 'less' : 'more'}`}</p>
        </div>
      </div>
      <Button
        className="w-full bg-brand-600 hover:bg-brand-700 my-4 md:my-0 text-sm"
        onClick={() => navigate(`/${ROUTE_REWARDS}${ROUTE_REWARDS_MY_REWARDS}`)}
      >
        Explore Rewards
      </Button>
    </div>
  )
}

Rewards.prototype = {
  data: PropTypes.object,
}

export default Rewards
