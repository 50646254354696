import { plaidApi } from '../../api'
import { useAuthQuery } from '@/hooks/use-auth-query'
import { saveLinkToken, getLinkToken } from '../../storage/plaid-provider'

export function useLinkToken({ finishingOAuth } = { finishingOAuth: false }) {
  return useAuthQuery({
    queryKey: ['plaid-link-token'],
    queryFn: async () => {
      if (finishingOAuth) {
        const previousLinkToken = getLinkToken()
        console.log('useLinkToken returning previous link token from storage', previousLinkToken)
        return previousLinkToken
      } else {
        const newLinkToken = await plaidApi.createLinkToken()
        saveLinkToken(newLinkToken)
        return newLinkToken
      }
    },
  })
}
