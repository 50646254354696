export const ROUTE_SIGN_IN = '/sign-in'
export const ROUTE_SIGN_UP = '/sign-up'
export const ROUTE_FORGOT_PASSWORD = '/forgot-password'
export const ROUTE_EMAIL_VERIFICATION = '/email-verification'
export const ROUTE_APPLICATION_RESULT = '/application-result'
export const ROUTE_ENFORCE_MFA = '/enforce-mfa'

export const ROUTE_ROOT = '/'
export const ROUTE_HOME = 'home'

export const ROUTE_ACCOUNT = 'account/'
export const ROUTE_ACCOUNT_MANAGE_CARD = 'manage-card'
export const ROUTE_ACCOUNT_INFO_AND_SECURITY = 'account-information-and-security'
export const ROUTE_ACCOUNT_MORE = 'more'

export const ROUTE_TRANSACTIONS = 'transactions/'
export const ROUTE_TRANSACTIONS_ALL_TRANSACTIONS = 'all-transactions'
export const ROUTE_TRANSACTIONS_STATEMENTS = 'statements'

export const ROUTE_PAYMENTS = 'payments/'
export const ROUTE_PAYMENTS_MAKE_PAYMENT = 'make-payment'
export const ROUTE_PAYMENTS_PAYMENT_HISTORY = 'payment-history'
export const ROUTE_PAYMENTS_AUTO_PAY = 'auto-pay'
export const ROUTE_PAYMENTS_PAYMENT_METHODS = 'payment-methods'
export const ROUTE_PAYMENT_PAY_BY_CHECK = 'pay-by-check'

export const ROUTE_REWARDS = 'rewards/'
export const ROUTE_REWARDS_BENEFITS = 'benefits'
export const ROUTE_REWARDS_MY_REWARDS = 'my-rewards'

export const ROUTE_VEHICLES = 'vehicles/'
export const ROUTE_VEHICLES_MANAGE_VEHICLES = 'manage-vehicles'

export const ROUTE_SUPPORT = 'support/'
export const ROUTE_SUPPORT_FAQ = 'faq'
export const ROUTE_SUPPORT_CONTACT_US = 'contact-us'

// Deep link alias
// This will be expose to user to make the link sorter and readable
export const ROUTE_ACTIVATE_CARD_DIALOG_ALIAS = '/activate'

// Deep link routes
// Internally we redirect user to these links
export const ROUTE_ACTIVATE_CARD_DIALOG_DEEP_LINK = `/account/manage-card?activate-card=true`
