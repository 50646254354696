import { useNavigate } from 'react-router-dom'
import { StaticAnnouncement, StaticAnnouncementAction } from '@/components/static-announcement'
import { Icons } from '@/theme/Images'
import { ROUTE_PAYMENTS, ROUTE_PAYMENTS_MAKE_PAYMENT } from '../routes/paths'
import { useAuth } from '@/contexts/auth.context'

const FinancialAccountDelinquentSuspendedBanner = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  return (
    user?.isFinancialAccountDelinquentAndSuspended?.() && (
      <div className={'md:px-[10%] md:pt-[2%] bg-bgGray md:p-0 px-6 pt-6'}>
        <div className="flex flex-col md:gap-8 gap-5">
          <StaticAnnouncement
            startIcon={Icons.grayNotificationIcon}
            text="Your account has been suspended due to a past-due payment. Your card cannot be used until a payment is made. Please pay now or reach out to help@fasten.cc for support."
            action={
              <StaticAnnouncementAction
                text="Make payment"
                handleClick={() => navigate(`/${ROUTE_PAYMENTS}${ROUTE_PAYMENTS_MAKE_PAYMENT}`)}
              />
            }
          />
        </div>
      </div>
    )
  )
}

export default FinancialAccountDelinquentSuspendedBanner
