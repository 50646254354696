import { useState, useRef } from 'react'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'

import PropTypes from 'prop-types'
import { cn } from '@/lib/utils'

const Dropdown = ({
  value,
  placeholder,
  onChange,
  label,
  options = [],
  helpText,
  onBlur,
  className,
  disabled = false,
  id,
  dataTestId,
}) => {
  const [lastSelectedIndex, setLastSelectedIndex] = useState(-1)
  const selectItemsRefs = useRef([])

  const handleKeyDown = event => {
    const currentIndex = selectItemsRefs.current.findIndex(ref => ref?.current === document.activeElement)
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      const nextIndex = Math.min(currentIndex + 1, options.length - 1)
      selectItemsRefs.current[nextIndex]?.current?.focus()
    } else if (event.key === 'ArrowUp') {
      event.preventDefault()
      const prevIndex = Math.max(currentIndex - 1, 0)
      selectItemsRefs.current[prevIndex]?.current?.focus()
    }
  }

  const handleDropdownOpen = () => {
    const indexToFocus = lastSelectedIndex !== -1 ? lastSelectedIndex : 0
    selectItemsRefs.current[indexToFocus]?.current?.focus()
  }

  return (
    <div className={cn('flex flex-col items-start self-stretch gap-1.5', className)}>
      {label && <p className="text-sm font-medium text-[--gray-700]">{label}</p>}
      <Select
        className="border-0 focus-visible:ring-0"
        value={value}
        onValueChange={onChange}
        onOpenChange={isOpen => isOpen && handleDropdownOpen()}
        id={id}
      >
        <SelectTrigger
          className={cn(value ? 'text-[--gray-900]' : 'font-[400] text-[--gray-500]', className)}
          disabled={disabled}
          onBlur={onBlur}
          onKeyDown={handleKeyDown}
          data-testid={dataTestId}
        >
          <SelectValue placeholder={placeholder} value={value} data-testid="dropdown" />
        </SelectTrigger>
        <SelectContent className="bg-white">
          <SelectGroup>
            {options.map((option, index) => (
              <SelectItem
                key={option.value}
                value={option.value}
                ref={el => (selectItemsRefs.current[index] = el)}
                className="hover:bg-[--gray-50] focus:bg-[--gray-50]"
                onFocus={() => setLastSelectedIndex(index)}
              >
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
      {helpText && <div className="text-sm text-gray-500">{helpText}</div>}
    </div>
  )
}

Dropdown.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  helpText: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  dataTestId: PropTypes.string,
}
export { Dropdown }
