import { createContext, useContext, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from './auth.context'
import { connectWebSocket, disconnectWebSocket } from '../services/web-socket-service'

const WebSocketContext = createContext(null)

const WebSocketProvider = ({ children }) => {
  const { user } = useAuth()
  const userId = user?.customer?.id
  const socketRef = useRef(null)

  useEffect(() => {
    if (!userId) return
    const socket = connectWebSocket(userId)
    socketRef.current = socket
    return () => {
      if (socketRef.current) {
        disconnectWebSocket(socketRef.current)
        socketRef.current = null
      }
    }
  }, [userId])

  const subscribeToEvent = useCallback((event, callback) => {
    if (!socketRef.current) return () => {}
    socketRef.current.on(event, callback)
    return () => {
      if (socketRef.current) {
        socketRef.current.off(event, callback)
      }
    }
  }, [])

  return <WebSocketContext.Provider value={{ socketRef, subscribeToEvent }}>{children}</WebSocketContext.Provider>
}

const useWebSocket = () => {
  const context = useContext(WebSocketContext)
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider')
  }
  return context
}

// eslint-disable-next-line react-refresh/only-export-components
export { WebSocketProvider, useWebSocket }

WebSocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
