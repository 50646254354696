import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

/**
 * Converts a string with underscores to a more readable format.
 *
 * @param {string} str - The input string to convert.
 * @returns {string} The converted string with the first letter capitalized and underscores replaced with spaces.
 */
export function convertToReadableString(str) {
  return str
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

/**
 * Checks if a string contains at least one special character.
 *
 * @param {string} str - The input string to check.
 * @returns {boolean} True if the string contains at least one special character, otherwise false.
 */
export function containsSpecialChar(str) {
  const regex = /[^\w\s]|_/
  return regex.test(str)
}

export function maskPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return ''
  }

  const lastFourDigits = phoneNumber.slice(-4)
  return `•••-•••-${lastFourDigits}`
}

/**
 * Converts seconds to a time format (M:SS).
 * @param {number} s - The number of seconds to convert.
 * @returns {string} The time formatted as M:SS.
 */
export function sToTime(s) {
  /**
   * Pads a number with leading zeros if necessary.
   * @param {number} n - The number to pad.
   * @param {number} [z=2] - The number of digits to pad to.
   * @returns {string} The padded number as a string.
   */
  function pad(n, z) {
    z = z || 2
    return ('0' + n).slice(-z)
  }

  var secs = s % 60
  s = (s - secs) / 60
  var mins = s % 60

  return mins + ':' + pad(secs)
}

/**
 * Formats a string as a currency.
 *
 * @param { string } value
 * @returns { string } - The value with a currency mask applied.
 */
export const currencyMask = value => {
  // Remove non-numeric characters except for the decimal point
  const formattedValue = value.replace(/[^0-9.]/g, '')

  // If formattedValue is empty, return an empty string
  if (!formattedValue) {
    return ''
  }

  // Split the value into integer and decimal parts
  const [integerPart, decimalPart] = formattedValue.split('.')

  // Format the integer part with commas
  const formattedInteger = new Intl.NumberFormat('en-US').format(integerPart)

  // Return the formatted value with the decimal part (if exists)
  return decimalPart !== undefined ? `${formattedInteger}.${decimalPart}` : formattedInteger
}

/**
 * Formats a string as a currency with two decimal.
 *
 * @param { string } value
 * @returns { string } - The value with a two decimals filled.
 */
export const fillDecimals = value => {
  let result = value
  const decimalValue = value?.split('.')[1]
  if (decimalValue === undefined) result = value + '.00'
  else if (decimalValue.length == 1) result = value + '0'
  return result
}

/**
 * Converts a ReadableStream into a JSON object.
 * @param {ReadableStream} body - The ReadableStream to convert to JSON.
 * @returns {Promise<Object>} - A promise that resolves to the JSON object parsed from the ReadableStream.
 */
export async function readableStreamtoJSON(body) {
  const reader = body.getReader() // `ReadableStreamDefaultReader`
  const decoder = new TextDecoder()
  const chunks = []

  async function read() {
    const { done, value } = await reader.read()

    // all chunks have been read?
    if (done) {
      return JSON.parse(chunks.join(''))
    }

    const chunk = decoder.decode(value, { stream: true })
    chunks.push(chunk)
    return read() // read the next chunk
  }

  return read()
}

/**
 * Asynchronously reads the body of an HTTP request and parses it as JSON.
 * @param {ReadableStream} body - The ReadableStream object representing the body of the HTTP request.
 * @returns {Promise<Object>} A Promise that resolves with the parsed JSON object.
 * @throws {Error} If there's an error parsing the request body as JSON.
 */
export async function readRequestBody(body) {
  let bodyData = ''

  // Using a for-await-of loop to handle asynchronous data consumption
  for await (const chunk of body) {
    bodyData += chunk
  }

  // Parsing the accumulated data as JSON
  try {
    const parsedBody = JSON.parse(bodyData)
    return parsedBody
  } catch (error) {
    throw new Error('Error parsing request body as JSON')
  }
}

/**
 * Checks if an object contains the exact keys from a known set of possible keys, including nested objects.
 * @param {Object} obj - The object to check.
 * @param {Object} possibleKeys - The object with possible keys.
 * @returns {boolean} - True if the object contains the exact keys, false otherwise.
 */
export function hasExactKeys(obj, possibleKeys) {
  // Get the keys of the object and possibleKeys
  const objKeys = Object.keys(obj)
  const keySet = Object.keys(possibleKeys)

  // Check if the length of object keys matches the length of possible keys
  if (objKeys.length !== keySet.length) {
    return false
  }

  // Sort both arrays to ensure order doesn't matter
  objKeys.sort()
  keySet.sort()

  // Check if each key in the object matches the possible keys
  for (let i = 0; i < objKeys.length; i++) {
    if (objKeys[i] !== keySet[i]) {
      return false
    }

    // If the value is an object, recursively check its keys
    if (typeof obj[objKeys[i]] === 'object' && obj[objKeys[i]] !== null) {
      if (!hasExactKeys(obj[objKeys[i]], possibleKeys[objKeys[i]])) {
        return false
      }
    }
  }

  return true
}

//masks the firt four digits of credit card number
export function maskCreditCardNumber(last4Digits) {
  if (!last4Digits) {
    return '*'.repeat(8)
  }
  const maskedSection = '*'.repeat(4)
  return maskedSection + last4Digits
}

export function addHoursToDate(date, hours) {
  const hoursToAdd = hours * 60 * 60 * 1000
  date.setTime(date.getTime() + hoursToAdd)
  return date
}

/**
 * Returns the ordinal suffix for a given number.
 *
 * Ordinal suffixes are used to indicate position in a sequence
 * (e.g., 1st, 2nd, 3rd, 4th, etc.).
 *
 * Special cases:
 * - Numbers ending in 11, 12, or 13 always use "th".
 * - Otherwise:
 *   - 1 → "st"
 *   - 2 → "nd"
 *   - 3 → "rd"
 *   - Other numbers → "th"
 *
 * @param num - The number to determine the ordinal suffix for.
 * @returns The ordinal suffix as a string ("st", "nd", "rd", or "th").
 */
export function getOrdinalSuffix(num) {
  const remainder = num % 10
  const exception = num % 100

  if (exception >= 11 && exception <= 13) {
    return 'th'
  }

  switch (remainder) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}
