import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { StaticAnnouncement } from '@/components/static-announcement'
import { Icons } from '@/theme/Images'
import {
  ROUTE_ACCOUNT_MANAGE_CARD,
  ROUTE_ACCOUNT_INFO_AND_SECURITY,
  ROUTE_ACCOUNT_MORE,
  ROUTE_HOME,
  ROUTE_TRANSACTIONS_ALL_TRANSACTIONS,
  ROUTE_TRANSACTIONS_STATEMENTS,
  ROUTE_PAYMENTS_AUTO_PAY,
  ROUTE_PAYMENTS_MAKE_PAYMENT,
  ROUTE_PAYMENTS_PAYMENT_HISTORY,
  ROUTE_PAYMENTS_PAYMENT_METHODS,
  ROUTE_PAYMENT_PAY_BY_CHECK,
  ROUTE_REWARDS_BENEFITS,
  ROUTE_REWARDS_MY_REWARDS,
  ROUTE_VEHICLES_MANAGE_VEHICLES,
} from '@/routes/paths'
import useFetchPlaidBanner from '../pages/hooks/use-plaid-banner'
import PlaidRecurringModal from '../pages/plaid-recurring-page/plaid-recuring-modal'
import { usePaymentMethods } from '../pages/payments-page/hooks/use-payment-methods'

const PlaidRecurringBanner = ({ setIsLoading }) => {
  const pathname = useLocation().pathname.split('/').at(-1)
  const { data: status, isLoading, isError } = useFetchPlaidBanner()
  const { publicTokenMutate } = usePaymentMethods({
    setIsPaymentMethodAdding: setIsLoading,
    monitor: true,
  })
  const bankAccountMonitored = !isLoading && !isError && status

  const showPlaidRecurringBanner = [
    ROUTE_HOME,
    ROUTE_ACCOUNT_MANAGE_CARD,
    ROUTE_ACCOUNT_INFO_AND_SECURITY,
    ROUTE_ACCOUNT_MORE,
    ROUTE_TRANSACTIONS_ALL_TRANSACTIONS,
    ROUTE_TRANSACTIONS_STATEMENTS,
    ROUTE_PAYMENTS_AUTO_PAY,
    ROUTE_PAYMENTS_MAKE_PAYMENT,
    ROUTE_PAYMENTS_PAYMENT_HISTORY,
    ROUTE_PAYMENTS_PAYMENT_METHODS,
    ROUTE_PAYMENT_PAY_BY_CHECK,
    ROUTE_REWARDS_BENEFITS,
    ROUTE_REWARDS_MY_REWARDS,
    ROUTE_VEHICLES_MANAGE_VEHICLES,
  ].includes(pathname)

  if (status === undefined || !showPlaidRecurringBanner || bankAccountMonitored) return null

  const bannerClass = [ROUTE_HOME, ROUTE_TRANSACTIONS_ALL_TRANSACTIONS, ROUTE_TRANSACTIONS_STATEMENTS].includes(
    pathname
  )
    ? 'md:px-[10%] md:pt-[2%] bg-bgGray md:p-0 px-0 py-6'
    : 'md:px-[10%] md:pt-[2%] bg-bgGray md:p-0 px-6 pt-6'

  return (
    <div className={bannerClass}>
      <div className="flex flex-col md:gap-8 gap-5">
        <StaticAnnouncement
          startIcon={Icons.grayNotificationIcon}
          text="Your vehicle lease or loan payment account is not connected. Without this, you will be ineligible to earn rewards in this category and won't be able to take full advantage of our benefits."
          action={<PlaidRecurringModal publicTokenMutate={publicTokenMutate} />}
        />
      </div>
    </div>
  )
}

PlaidRecurringBanner.propTypes = {
  setIsLoading: PropTypes.func,
}

export default PlaidRecurringBanner
