import { useMutation, useQueryClient } from '@tanstack/react-query'
import { plaidApi } from '../../api'

export function usePublicToken({ monitor = false } = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['new-external-bank-account'],
    mutationFn: payload => plaidApi.exchangePublicToken({ ...payload, monitor }),
    onSuccess: () => {
      if (monitor) queryClient.setQueryData(['plaid-banner'], true)
    },
  })
}
