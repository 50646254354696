const LoadingScreen = () => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-10 flex justify-center items-center z-50">
      <div className="bg-white p-1 rounded-xl border border-gray-200 shadow-lg flex justify-center items-center">
        <svg className="w-12 h-12 animate-spin" viewBox="0 0 50 50">
          <circle className="text-gray-200" cx="25" cy="25" r="20" fill="none" stroke="currentColor" strokeWidth="6" />
          <circle
            className="text-brand-600"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke="currentColor"
            strokeWidth="6"
            strokeDasharray="125"
            strokeDashoffset="100"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  )
}

export default LoadingScreen
