//Icon Imports
import carFrontIcon from '../assets/icons/car.svg'
import closeIcon from '../assets/icons/close.svg'
import featuredOneIcon from '../assets/icons/featured-icon-one.svg'
import featuredTwoIcon from '../assets/icons/featured-icon-two.svg'
import featuredThreeIcon from '../assets/icons/featured-icon-three.svg'
import roundedGrayPlusIcon from '../assets/icons/rounded-gray-plus.svg'
import roundedGrayMinusIcon from '../assets/icons/rounded-gray-minus.svg'
import helpIcon from '../assets/icons/help.svg'
import helpBoldIcon from '../assets/icons/help-bold.svg'
import grayIndicatorIcon from '../assets/icons/gray-indicator.svg'
import blueIndicatorIcon from '../assets/icons/blue-indicator.svg'
import searchIcon from '../assets/icons/search.svg'
import userIcon from '../assets/icons/user.svg'
import bellIcon from '../assets/icons/bell.svg'
import bellOutlinedIcon from '../assets/icons/bell-outlined.svg'
import menuIcon from '../assets/icons/menu.svg'
import boldCloseIcon from '../assets/icons/bold-close.svg'
import logoutIcon from '../assets/icons/logout.svg'
import filterIcon from '../assets/icons/filter-lines.svg'
import greenCheckIcon from '../assets/icons/green-check.svg'
import calendarIcon from '../assets/icons/calendar.svg'
import filledCardIcon from '../assets/icons/filled-card.png'
import plusIcon from '../assets/icons/plus.svg'
import filledNewPaymentMethodIcon from '../assets/icons/filled-new-payment-method.png'
import filledRemovePaymentMethodIcon from '../assets/icons/filled-remove-card.png'
import filledCashNoteIcon from '../assets/icons/filled-money-note.png'
import arrowLeftIcon from '../assets/icons/arrow-left.svg'
import arrowRightIcon from '../assets/icons/arrow-right.svg'
import arrowNarrowUpRightIcon from '../assets/icons/arrow-narrow-up-right.svg'
import arrowUpRightIcon from '../assets/icons/arrow-up-right.svg'
import currencyDollarCircleIcon from '../assets/icons/currency-dollar-circle.svg'
import xCircleIcon from '../assets/icons/x-circle.svg'
import checkCircleIcon from '../assets/icons/check-circle.svg'
import fastenTextLogoBlack from '../assets/images/fasten-text-logo-black.svg'
import checkboxCheckedIcon from '../assets/icons/checkbox-checked.svg'
import grayCheckboxCheckedIcon from '../assets/icons/gray-checkbox-checked.svg'
import checkboxUncheckedIcon from '../assets/icons/checkbox-unchecked.svg'
import checkboxUncheckedErrorIcon from '../assets/icons/checkbox-unchecked-error.svg'
import checkboxUncheckedCircleIcon from '../assets/icons/checkbox-unchecked-circle.svg'
import SuccessToastIcon from '../assets/icons/success-toast-icon.jsx'
import FailureToastIcon from '../assets/icons/failure-toast-icon.jsx'
import grayNotificationIcon from '../assets/icons/gray-notification.svg'
import removeVehicleIcon from '../assets/icons/remove-vehicle-icon.png'
import FailureAlertIcon from '../assets/icons/failure-alert-icon.jsx'
import emailBoxIcon from '../assets/icons/email-box.svg'
import UserCircleAvatarIcon from '../assets/icons/UserCircleAvatar'
import ApplePaymentMethodIcon from '../assets/icons/payment-method-icon-apple.png'
import GPayPaymentMethodIcon from '../assets/icons/payment-method-icon-gPay.png'
import DocumentUploadHeaderIcon from '../assets/icons/document-upload.png'
import ShieldSecurityIcon from '../assets/icons/shield-security-icon.jsx'
import DeleteIcon from '../assets/icons/delete.png'
import DocumentUploadIcon from '../assets/icons/upload-doc.png'
import fastenTextLogoWhite from '../assets/icons/fasten-logo-yellow.svg'
import redeemPointsVehicleExpenses from '../assets/icons/redeem-points-vehicle-expenses.png'
import redeemPointsAmazonGiftCard from '../assets/icons/redeem-points-amazon-gift-card.png'
import fastenTextLogoBlueYellow from '../assets/icons/fasten-text-logo-blue-yellow.png'
import chainIcon from '../assets/icons/chain-icon.png'
import paymentActivityIcon from '../assets/icons/payment-activity-icon.svg'
import checkSquareBrokenIcon from '../assets/icons/check-square-broken.png'

//Image Imports
import sidebarBgImage from '../assets/images/sidebar-bg.png'
import creditCardMockup from '../assets/images/credit-card-mockup.png'
import cardApplicationSidebar from '../assets/images/card-application-sidebar.png'
import cardApplicationSubmit from '../assets/images/card-application-submit.png'
import sampleBankLogo from '../assets/images/sample-bank-logo.png'
import sampleAccountNumber from '../assets/images/sample-account-number.png'
import creditCardMockupWithName from '../assets/images/credit-card-mockup-with-name.png'
import creditCardMockupName from '../assets/images/credit-card-mockup-name.png'
import usFlagCirle from '../assets/images/us-flag.svg'
import updatePersonalInformationDialogIcon from '../assets/images/update-personal-information-dialog-icon.png'
import creditCardMockupCvcStepOne from '../assets/images/credit-card-mockup-cvc-step-one.png'
import creditCardMockupNew from '../assets/images/credit-card-mockup-new.png'
import creditCardMockupCvcStepOneNew from '../assets/images/credit-card-mockup-cvc-step-one-new.png'
import creditCardBackground from '../assets/images/credit-card-background.png'
import amazonGiftCard from '../assets/images/amazon-gift-card.png'
import avatarIcon from '../assets/icons/avatarIcon.png'

//Icon Exports
export const Icons = {
  carFrontIcon,
  closeIcon,
  featuredOneIcon,
  featuredTwoIcon,
  featuredThreeIcon,
  roundedGrayPlusIcon,
  roundedGrayMinusIcon,
  helpIcon,
  grayIndicatorIcon,
  searchIcon,
  userIcon,
  bellIcon,
  menuIcon,
  boldCloseIcon,
  logoutIcon,
  helpBoldIcon,
  filterIcon,
  greenCheckIcon,
  calendarIcon,
  filledCardIcon,
  bellOutlinedIcon,
  plusIcon,
  filledNewPaymentMethodIcon,
  filledRemovePaymentMethodIcon,
  filledCashNoteIcon,
  removeVehicleIcon,
  arrowLeftIcon,
  arrowRightIcon,
  arrowNarrowUpRightIcon,
  arrowUpRightIcon,
  currencyDollarCircleIcon,
  xCircleIcon,
  checkCircleIcon,
  SuccessToastIcon,
  FailureToastIcon,
  grayNotificationIcon,
  FailureAlertIcon,
  fastenTextLogoBlack,
  checkboxCheckedIcon,
  grayCheckboxCheckedIcon,
  checkboxUncheckedIcon,
  checkboxUncheckedErrorIcon,
  checkboxUncheckedCircleIcon,
  emailBoxIcon,
  UserCircleAvatarIcon,
  ApplePaymentMethodIcon,
  GPayPaymentMethodIcon,
  DocumentUploadHeaderIcon,
  ShieldSecurityIcon,
  DeleteIcon,
  DocumentUploadIcon,
  redeemPointsVehicleExpenses,
  redeemPointsAmazonGiftCard,
  fastenTextLogoBlueYellow,
  chainIcon,
  avatarIcon,
  blueIndicatorIcon,
  paymentActivityIcon,
  checkSquareBrokenIcon,
}

//Image Exports
export const Images = {
  sidebarBgImage,
  creditCardMockup,
  fastenTextLogoWhite,
  cardApplicationSidebar,
  cardApplicationSubmit,
  sampleBankLogo,
  sampleAccountNumber,
  creditCardMockupWithName,
  creditCardMockupName,
  usFlagCirle,
  updatePersonalInformationDialogIcon,
  creditCardMockupCvcStepOne,
  creditCardMockupNew,
  creditCardMockupCvcStepOneNew,
  creditCardBackground,
  amazonGiftCard,
}
