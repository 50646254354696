import { END_POINTS } from '../end-points'
import fetch from '../fetch-with-authorization'

export const getExternalBankAccounts = async () =>
  fetch({
    path: END_POINTS.PAYMENT.BANK_ACCOUNTS,
  })

export const getRecurringPayment = async () =>
  fetch({
    path: END_POINTS.PAYMENT.RECURRING_PAYMENT,
  })

export const allPayments = async ({ pageParam }) =>
  fetch({
    path:
      END_POINTS.PAYMENT.PAYMENT_HISTORY +
      (pageParam?.endCursor ? `?cursor=${pageParam?.endCursor}` : '') +
      (pageParam?.cursorType ? `?cursor-type=${pageParam?.cursorType}` : ''),
  })

export const hasScheduledOneTimePayment = async () =>
  fetch({
    path: END_POINTS.PAYMENT.HAS_ONE_TIME_PAYMENTS,
  })

export const removeRecurringPayment = async () =>
  fetch({
    path: END_POINTS.PAYMENT.RECURRING_PAYMENT,
    method: 'DELETE',
  })

export const schedulePayment = async ({ externalBankAccountId, amount, strategy, transferDate }) =>
  fetch({
    path: END_POINTS.PAYMENT.SCHEDULE_PAYMENT,
    method: 'POST',
    body: JSON.stringify({ externalBankAccountId, amount, strategy, transferDate }),
  })

export const scheduleRecurringPayment = async ({ externalBankAccountId, amount, strategy, transferDayOfMonth }) =>
  fetch({
    path: END_POINTS.PAYMENT.SCHEDULE_RECURRING_PAYMENT,
    method: 'POST',
    body: JSON.stringify({ externalBankAccountId, amount, strategy, transferDayOfMonth }),
  })

export const updateDefaultPaymentMethod = async ({ externalBankAccountId }) =>
  fetch({
    path: END_POINTS.PAYMENT.PAYMENT_METHODS + `/${externalBankAccountId}`,
    method: 'PUT',
  })

export const removePaymentMethod = async ({ externalBankAccountId }) =>
  fetch({
    path: END_POINTS.PAYMENT.PAYMENT_METHODS + `/${externalBankAccountId}`,
    method: 'DELETE',
  })
