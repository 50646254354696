import { Navigate, Outlet, useLocation, useOutlet } from 'react-router-dom'
import { ROUTE_APPLICATION_RESULT, ROUTE_HOME, ROUTE_SIGN_IN } from './routes/paths.js'
import { useEffect, useState } from 'react'

import PrivateLayout from '@/components/layout/private-layout.jsx'
import { useAuth } from '@/contexts/auth.context'
import LoadingScreen from './components/loading-screen.jsx'
import { useIdleTimer } from 'react-idle-timer'
import { setSessionExpired } from '@/storage'

export default function App() {
  const { authenticated, isTokenRefreshing, logout } = useAuth()
  const { pathname } = useLocation()
  const outletContent = useOutlet()

  const onIdle = async () => {
    setSessionExpired(true)
    await logout()
  }

  useIdleTimer({
    timeout: 15 * 60 * 1000, // 15 minutes
    onIdle,
    debounce: 500,
  })
  // General loading state
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!isTokenRefreshing) {
      console.log('Token refresh completed, updating UI...')
    }
  }, [isTokenRefreshing])

  if (!authenticated) {
    return <Navigate to={ROUTE_SIGN_IN} replace />
  }

  if (!outletContent) {
    return <Navigate to={ROUTE_HOME} replace />
  }

  if (isTokenRefreshing) {
    return <LoadingScreen />
  }

  return pathname === ROUTE_APPLICATION_RESULT ? (
    <>
      <Outlet context={{ setIsLoading }} />
      {isLoading && <LoadingScreen />}
    </>
  ) : (
    <PrivateLayout setIsLoading={setIsLoading}>
      <Outlet context={{ setIsLoading }} />
      {isLoading && <LoadingScreen />}
    </PrivateLayout>
  )
}
