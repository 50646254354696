import { END_POINTS } from '@/api/end-points'
import fetch from '../fetch-with-authorization'

export const createLinkToken = async () =>
  fetch({
    path: END_POINTS.PAYMENT.PLAID.CREATE_LINK_TOKEN,
  })

export const exchangePublicToken = async ({ publicToken, payload, monitor = false }) =>
  fetch({
    path: END_POINTS.PAYMENT.PLAID.EXCHANGE_PUBLIC_TOKEN,
    method: 'POST',
    body: JSON.stringify({ publicToken, payload, monitor }),
  })

export const fetchPlaidBannerStatus = async () => {
  const data = await fetch({
    path: END_POINTS.PAYMENT.PLAID.HAS_MONITORED_BANK_ACCOUNT,
  })
  return data
}
