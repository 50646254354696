import { Footer, NavBar } from '@/components/index'

import PropTypes from 'prop-types'
import UnlockCardStaticBanner from '@/pages/account-page/tabs/manage-card/unlock-card-static-banner.jsx'
import PlaidRecurringBanner from '../plaid-recurring-banner'
import useZendesk from '@/hooks/use-zendesk'
import FinancialAccountDelinquentSuspendedBanner from '../financial-account-delinquent-suspended-banner'

const PrivateLayout = ({ children, setIsLoading }) => {
  //Use the custom hook
  useZendesk()
  return (
    <>
      <NavBar />
      <FinancialAccountDelinquentSuspendedBanner />
      <UnlockCardStaticBanner />
      <PlaidRecurringBanner setIsLoading={setIsLoading} />
      {children}
      <Footer />
    </>
  )
}

PrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
  setIsLoading: PropTypes.func,
}

export default PrivateLayout
