import { useNavigate } from 'react-router'
import { Images } from '@/theme/Images'
import { Link } from 'react-router-dom'
import { getCurrentYear } from '@/utils/date-format-util'

const Footer = () => {
  const navigate = useNavigate()

  const LINK_ACCOUNT_MORE = 'account/more'

  return (
    <div className=" bottom w-auto h-[Hug (317px)] md:h-[218.32px] sticky absolute-top-[366px] absolute-left-[29998px] py-12 gap-16 bg-gray-900 flex items-center justify-cente  px-[1%] md:px-[8%] r">
      <div className=" flex flex-col w-[375px] h-[Hug (221px)] md:w-[100vw] md:h-[hug(122.32px)] px-8 gap-8">
        <div className=" flex flex-row justify-between">
          <div className="flex justify-start items-center w-[130px] h-[26.32px]">
            <img
              src={Images.fastenTextLogoWhite}
              alt="fasten text logo"
              className="w-[8rem] h-auto cursor-pointer"
              onClick={() => navigate('home')}
            />
          </div>
          <div className=" flex justify-end gap-8 w-[124px] h-[27px] md:w-[566px] md:h-[26.32px]">
            <Link to={LINK_ACCOUNT_MORE}>
              <div className="font-sans text-base font-semibold leading-6 text-left text-[#EAECF0]">Help</div>
            </Link>
            <Link to={LINK_ACCOUNT_MORE}>
              <div className="font-inter text-base font-semibold leading-6 text-left text-[#EAECF0]">Legal</div>
            </Link>
          </div>
          <div className="flex justify-start w-[296px] md:w-[220px] h-[24px] hidden md:block">
            <div className="font-inter text-base font-normal leading-6 text-right text-[rgba(152,162,179,1)]">
              © {getCurrentYear()} Fasten Rewards, Inc.
            </div>
          </div>
        </div>
        <div className=" flex flex-col h-[Hug (144px)] md:h-[64px] px-(15/4) md:px-1 font-inter text-base font-bold leading-6 text-left text-[rgba(152,162,179,1)] gap-4">
          Disclosure
          <span className="font-inter text-base font-normal leading-6 text-left">
            The Fasten Rewards™ Visa Card is powered by Highnote and issued by Celtic Bank pursuant to a license by
            Visa Inc.
          </span>
          <div className="flex justify-start w-[296px] md:w-[186px] h-[24px] block md:hidden">
            <div className="font-inter text-base font-normal leading-6 text-right text-[rgba(152,162,179,1)]">
              © Fasten Rewards {getCurrentYear()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = {}

export default Footer
