import { bankAccountApi } from '../../api'
import { useAuthQuery } from '../../hooks/use-auth-query'

export function useGetExternalBankAccounts(enabled = false) {
  return useAuthQuery({
    queryKey: ['external-bank-accounts'],
    enabled,
    queryFn: bankAccountApi.getExternalBankAccounts,
  })
}
