import { useEffect, useRef, useState } from 'react'
import { toast } from 'sonner'
import { ToastMessage } from '@/components'
import { useGetExternalBankAccounts } from '../../hooks/use-get-external-bank-accounts'
import { usePublicToken } from '../../hooks/use-public-token'

const MAX_POLLING_DURATION = 60000 // 60 seconds
const POLLING_INTERVAL = 5000 // 5 seconds

export const usePaymentMethods = (
  { fetchOnMount, setIsPaymentMethodAdding, monitor } = {
    fetchOnMount: false,
    setIsPaymentMethodAdding: () => {},
    monitor: false,
  }
) => {
  const [startPolling, setStartPolling] = useState(false)
  const [newAccountId, setNewAccountId] = useState(null)
  const startTimeRef = useRef(null)

  // Fetch the current payment methods
  const {
    data: externalBankAccountData,
    isFetched: isExternalBankAccountDataFetched,
    isError: isExternalBankAccountDataError,
    isLoading: isExternalBankAccountDataLoading,
    refetch: refetchExternalBankAccountData,
  } = useGetExternalBankAccounts(fetchOnMount)

  // Function to connect a non-monitored bank account
  const { mutateAsync } = usePublicToken({ monitor })

  async function publicTokenMutate(payload) {
    try {
      setIsPaymentMethodAdding(true)
      const newAccount = await mutateAsync(payload)
      if (Array.isArray(newAccount)) setNewAccountId(newAccount[0]?.id)
      setStartPolling(true) // Start polling when mutation is successful
      startTimeRef.current = Date.now() // Record the start time
    } catch (error) {
      setIsPaymentMethodAdding(false)
      throw error
    }
  }

  // Polling effect
  useEffect(() => {
    if (!startPolling || !startTimeRef.current) return

    const interval = setInterval(() => {
      const elapsedTime = Date.now() - startTimeRef.current

      if (elapsedTime >= MAX_POLLING_DURATION) {
        setStartPolling(false) // Stop polling after max duration
        setIsPaymentMethodAdding(false)
        clearInterval(interval)

        toast.custom(() => (
          <ToastMessage text="This is taking longer than expected. Your payment method may take a few moments to appear." />
        ))
        return
      }

      refetchExternalBankAccountData()
    }, POLLING_INTERVAL)

    return () => clearInterval(interval) // Cleanup on unmount
  }, [startPolling, refetchExternalBankAccountData, setIsPaymentMethodAdding])

  // Stop polling if new account is received
  useEffect(() => {
    if (startPolling) {
      const accountReceived = externalBankAccountData?.find(account => account?.node?.id === newAccountId)
      // setStartPolling(!accountReceived)
      if (accountReceived) {
        setStartPolling(false)
        setIsPaymentMethodAdding(false)

        toast.custom(() => (
          <ToastMessage
            success
            text="Account added successfully"
            description="Your new account is now active and can be used both to pay your balance and as a default payment method."
          />
        ))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalBankAccountData])

  return {
    externalBankAccountData,
    isExternalBankAccountDataLoading,
    isExternalBankAccountDataFetched,
    isExternalBankAccountDataError,
    publicTokenMutate,
  }
}
