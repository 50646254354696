import PropTypes from 'prop-types'
import { Icons } from '@/theme/Images'

const CustomCheckbox = ({ isChecked, onClick, error }) => {
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      onClick()
    }
  }

  return (
    <div
      role="checkbox"
      aria-checked={isChecked}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      className="mr-3 cursor-pointer"
    >
      {isChecked ? (
        <img src={Icons.checkboxCheckedIcon} alt="Checkbox" className="h-5 w-6" />
      ) : error ? (
        <img src={Icons.checkboxUncheckedErrorIcon} alt="Checkbox" className="h-5 w-6" />
      ) : (
        <img src={Icons.checkboxUncheckedIcon} alt="Checkbox" className="h-5 w-6" />
      )}
    </div>
  )
}

CustomCheckbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  error: PropTypes.bool,
}

export { CustomCheckbox }
