export function persistIntendedRouteWithSearchParams(location) {
  localStorage.setItem('intendedRoute', location?.pathname)
  localStorage.setItem('intendedRouteSearchParams', location?.search)
}

export function removeIntendedRouteWithSearchParams() {
  localStorage.removeItem('intendedRoute')
  localStorage.removeItem('intendedRouteSearchParams')
}

export function getIntendedRouteWithSearchParams() {
  return [localStorage.getItem('intendedRoute'), localStorage.getItem('intendedRouteSearchParams')]
}
