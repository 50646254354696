import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'

import { StatusBadge } from '@/components'
import TransactionsTableSkeleton from './transactions-table.skeleton'
import { format } from 'date-fns'
import { transactionsApi } from '@/api'
import { transformAmount } from '@/utils/string-format-utils'
import { useAuthQuery } from '@/hooks/use-auth-query'
import { useNavigate } from 'react-router'
import { convertUTCToET } from '@/utils/date-format-util'
import { ROUTE_TRANSACTIONS, ROUTE_TRANSACTIONS_ALL_TRANSACTIONS } from '../../routes/paths'

const TransactionsTable = () => {
  const navigate = useNavigate()

  const { isLoading: isTransactionsLoading, data: transactionData } = useAuthQuery({
    queryKey: ['recent_transactions'],
    queryFn: () => transactionsApi.getTransactions('', '', ''),
  })

  const { transactions } = transactionData || {}

  //Return amount text color according to the value
  const textColorSelector = (amount, sign) => {
    if (amount && amount === 0) {
      return 'text-dGray-600'
    } else if (amount && sign && sign === 'NEGATIVE') {
      return 'text-success-600'
    } else {
      return 'text-dGray-600'
    }
  }

  return (
    <div className="w-full h-auto py-8 px-4 md:px-5">
      <div className="flex flex-row justify-between items-center mb-5">
        <p className="font-semibold text-lg text-dGray-900">Recent Transactions</p>
        <div className="flex flex-row items-center">
          <p
            className="text-dGray-700 text-xs font-medium underline md:mr-0 cursor-pointer"
            onClick={() => navigate(`/${ROUTE_TRANSACTIONS}${ROUTE_TRANSACTIONS_ALL_TRANSACTIONS}`)}
          >
            View all
          </p>
        </div>
      </div>
      <div>
        {isTransactionsLoading ? (
          <TransactionsTableSkeleton />
        ) : (
          <Table className="w-full">
            <TableHeader>
              <TableRow className="w-full">
                <TableHead className="min-w-[130px] md:w-[20%] text-dGray-600 font-medium text-xs hidden md:table-cell">
                  Date
                </TableHead>
                <TableHead className="text-dGray-600 md:w-[20%] font-medium text-xs hidden md:table-cell">
                  Status
                </TableHead>
                <TableHead className="px-0 md:px-4 md:w-[50%] text-dGray-600 font-medium text-xs">
                  Merchant Name
                </TableHead>
                <TableHead className="px-0 md:px-4 md:w-[15%] text-dGray-600 font-medium text-xs text-right md:text-left">
                  Amount
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {transactions?.length > 0 && !isTransactionsLoading ? (
                transactions?.map((transaction, index) => (
                  <TableRow
                    key={transaction?.id ?? index}
                    className="p-0  h-[72px]"
                    data-testid="transaction-table-row"
                  >
                    <TableCell className="font-normal text-sm text-dGray-600 hidden md:table-cell">
                      {format(convertUTCToET(transaction?.transactionDate), 'MMM dd, yyyy') ?? '-'}
                    </TableCell>
                    <TableCell className="hidden md:table-cell">
                      <StatusBadge status={transaction?.isComplete ? 'posted' : 'pending'} />
                    </TableCell>
                    <TableCell className="w-max px-0 md:px-4 h-full ">
                      <div className="flex flex-col h-full">
                        <p className="text-sm font-medium text-dGray-900">
                          {transaction?.merchantName || transaction?.merchantDetails?.name}
                        </p>
                        <p className="font-normal text-sm text-dGray-600 md:hidden">
                          {format(convertUTCToET(transaction?.transactionDate), 'MMM dd, yyyy') ?? '-'}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell className={'px-0 md:px-4'}>
                      <div className="flex flex-col items-end md:items-start justify-center">
                        <p
                          className={`font-bold text-right md:text-left text-nowrap text-sm text-dGray-600 ${textColorSelector(
                            transaction?.amount,
                            transaction?.sign
                          )}`}
                        >
                          {transformAmount(transaction?.amount, transaction?.sign) ?? '-'}
                        </p>
                        <div className="md:hidden">
                          <StatusBadge status={transaction?.isComplete ? 'posted' : 'pending'} />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow className="w-full">
                  <TableCell className="font-normal text-sm text-dGray-600">No recent transactions available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </div>
    </div>
  )
}

export default TransactionsTable
